<template>
    <div class="v-application--wrap">
      <v-main style="background-color: #97a84b;">
        <transition name="fade-transform" mode="out-in">
          <v-container class="fill-height pa-0 text-center" fluid>
            <v-row class="align-center">
              <v-col>
                <p class="text-h4 font-weight-black">{{ $t('screenshot.invalid_or_expired') }}</p>
                <p>{{ $t('screenshot.denied_explanation') }}</p>
                <v-btn depressed large outlined class="black--text" to="/login"><v-icon left>mdi-home</v-icon>{{ $t('global.go_to_login') }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </transition>
      </v-main>
    </div>
  </template>
  <script>
  export default {};
  </script>